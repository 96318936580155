import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button, 
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Toolbar} from '@mui/material';
import {httpsCallable } from 'firebase/functions';
import firestoreFunctions from './firestoreFunctions'; 
class Riddles extends React.Component {

  state = {
    index : 0,
    riddles : [],
    doExpand : false
  };


  constructor(props){
    super(props)
    this.fetchRiddle = this.fetchRiddle.bind(this);
    this.nextRiddle = this.nextRiddle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchRiddle();
  }

  handleChange(answer){
    document.getElementById("IdAnswer").textContent = answer
  } 

  async fetchRiddle() {
    const addMessage = httpsCallable(firestoreFunctions,'fetchRiddle');
      addMessage({})
      .then((result) => {
        if(result && result.data && result.data.result && result.data.result.riddle){
         // this.state.index = Math.floor(Math.random() * result.data.result.riddle.length);
         this.state.index = 1; 
         this.setState({riddles : result.data.result.riddle});
        }
      });   
  }

  async nextRiddle() {
    this.handleChange("Unhide Answer");
    this.state.doExpand = false;
    if(this.state.index < this.state.riddles.length -1){
      this.setState((state) => {
        return {index: state.index + 1}
      });  
    }else{
      this.setState({index: -1});
      this.setState({riddles : []});
      await this.fetchRiddle();
    }
  }
   
  render () {
    const {riddles,index} = this.state;
    return (
          <div>
            { (riddles.length > 0 && index > -1 && index < riddles.length) ?
              <div>
                <Typography color="text.secondary" variant="h5" gutterBottom>
                  {riddles[index].label}
                </Typography>
                
                <Button id="IdAnswer" color="secondary" onClick={() => this.handleChange(riddles[index].answer)}>
                  <span className="myLowerCase">Unhide Answer</span>
                </Button>
                <div>  
                    <Button variant="outlined" gutterBottom style={{float: "right",margin: "10px"}} onClick={async () => {await this.nextRiddle()}}>
                      <span className="myLowerCase">Next Riddle</span>
                    </Button>
                </div>
              </div>    
              :
              <div>
                 <Typography component="div"  color="text.secondary" gutterBottom>
                  Wait while we are fetching Riddle ...
                </Typography> 
              </div>
            }
            </div>
  );
}
}

export default Riddles;

