import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { 
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography} from '@mui/material';

import Riddles from './Riddles';
import DumbCharades from './DumbCharades';
class GameCorner extends React.Component
{
    
    render () {
    return (
        <div>
            <Typography variant="h5" style={{ height:"100",color:"#304f6a"  }} justifyContent="center" color="text.secondary">
            </Typography>
            <div>
            <Accordion expanded='false'>
                <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ background:"#c1878754", height:"20px"}}
                    >
                    <Typography variant="h6" style={{color:"#304f6a" }} justifyContent="center" color="text.secondary">
                        Riddles
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Riddles/>
                </AccordionDetails>   
            </Accordion>
            </div>
            <Accordion expanded='true'>
                <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1b-content"
                      id="panel1b-header"
                      style={{ background:"#c1878754"}}
                    >
                    <Typography variant="h6" style={{color:"#304f6a"   }} justifyContent="center" color="text.secondary">
                        Dumb Charades
                    </Typography>
                    
                </AccordionSummary>
                <AccordionDetails style={{ backgroundA:"#cba0a0", flex:"centre" }}>
                    <DumbCharades/>
                </AccordionDetails>   
            </Accordion>
        </div> 
    )
    }
}
export default GameCorner