import React from 'react';
import { Typography, Link, Toolbar } from '@mui/material';
class Footer extends React.Component {
  
    render() {
        return (
            
            <div className="bottomdiv" >
                {'Copyright © '}
                <Link color="#28b362" href="https://www.dumbcharades.in/">
                    www.dumbcharades.in
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </div>
        )
    }
}
export default Footer