import React from 'react';
import { 
    Typography} from '@mui/material';
import { Block } from '@mui/icons-material';

    
function Introduction(props)
{

    return (
        <div className='introStyle'>
            <Typography variant='h3' style={{maxwidth: "100vw",  fontWeight:'900'}} color= "#ff3d3d">
                Play Dumb Charades
            </Typography>  
            <Typography style={{maxwidth: "100vw",  marginTop: "20px"}} color='text.secondary'>
                Do Acting of below movie and have your friends and family guess it.
            </Typography>  
            
        </div> 
    )
}
export default Introduction