import React from "react"
import Header from "./components/Header";
import Footer from "./components/Footer";
import Introduction from "./components/Introduction";
import GameCorner from "./components/GameCorner";
import './style.css';
import {
  Box,
  Grid,
  Toolbar,
  Typography} from '@mui/material';
import LeftPane from "./components/LeftPane";
import DumbCharades from "./components/DumbCharades";
function App() {
  return (
    <div className="App">
      {/* <Header/> */}
      {/* <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} >
            <Introduction/>
        </Grid>
      </Grid> */}
      <Grid container>
      <Introduction/>
      
      {/* <Box
        component="img"
        sx={{
          marginTop: "-50px",
          width: "100vw",
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="Play Dumb Charades"
        src="/DC.png?auto=format"
      />
       */}
        <Grid item xs={12} sm={12} md={12} lg={12} >
            <DumbCharades/>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Footer/>
        </Grid>
      </Grid>
    </div>
  );
}
export default App;