import React from 'react';
import {Button, 
  Grid,
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Toolbar,
  Card} from '@mui/material';

const styles = (theme) => ({
  root: {
    backgroundColor: 'blue',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'red',
    },
  },
});


class DumbCharades extends React.Component {

  state = {
    index : 0,
    total_pages: {
      kn: 1,
      hi: 11,
      ta: 5,
      ml:8
    },
    movie_vote: {
      kn: 10,
      hi: 20,
      ta: 10,
      ml:10
    },
    movie_year : 2022,
    current_page: 1,
    vote_count : 0,
    movieList : [],
    movieLanguage: 'hi'
  };

  constructor(props){
    super(props)
    this.nextMovie = this.nextMovie.bind(this);
    this.fetchMovieAgain = this.fetchMovieAgain.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchMovieAgain(this.state.movieLanguage);
  }

  handleChange(event){
    //this.setState({radioValue: event.target.value});
    this.state.movieLanguage = event.target.value;
    if(event.target.value === "hi" || event.target.value === "ta" || event.target.value === "kn" || event.target.value === "ml" ){
      this.fetchMovieAgain(event.target.value);
    }else{
      this.fetchMovieAgain("hi");
    } 
  } 

  async fetchMovieAgain(movieLanguage) {
    let current_page_var = Math.floor(Math.random() * this.state.total_pages[movieLanguage]);
    if(current_page_var === 0)
      current_page_var = 1;
    // do{
    //   current_page_var = Math.floor(Math.random() * this.state.total_pages[movieLanguage]);
    //   if(current_page_var === 0)
    //     current_page_var = 1;
    // }while(current_page_var === this.state.current_page)
    this.state.movieList = [];
    this.state.index = 0;
    this.state.current_page = current_page_var;
    //let url = "https://api.themoviedb.org/3/discover/movie?api_key=b1c24ca0afa48aa2bd90b6b9bfb995e6&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=" + current_page_var + "&primary_release_year="+ this.state.movie_year +"&with_original_language=hi&with_watch_monetization_types=flatrate";
    //https://api.themoviedb.org/3/discover/movie?api_key=b1c24ca0afa48aa2bd90b6b9bfb995e6&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=4&with_original_language=ta&with_watch_monetization_types=flatrate&vote_count.gte=20
    let url = "https://api.themoviedb.org/3/discover/movie?api_key=b1c24ca0afa48aa2bd90b6b9bfb995e6&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=" + current_page_var +"&with_original_language=" + movieLanguage + "&with_watch_monetization_types=flatrate&vote_count.gte=" + this.state.movie_vote[movieLanguage];
    
    const response = await fetch(url);
    const json = await response.json();
    if(json && json.results && json.results.length > 0){
      this.state.total_pages[movieLanguage] = json.total_pages;
      this.state.total_results = json.total_results;
      json.results.forEach((element) => {    
        if(element.vote_count >= this.state.movie_vote[movieLanguage]){
            this.state.movieList.push({
              id: element.id,
              original_title: element.original_title,
              poster_path: element.poster_path,
              vote_count : element.vote_count,
              title : element.title,
              overview : element.overview
            })
            if(this.state.index <= 0)
            this.setState({index : 0});
          }
      },this);
     
    }
  }

  async nextMovie() {
    if(this.state.index < this.state.movieList.length -1){
      this.setState((state) => {
        return {index: state.index + 1}
      });  
    }else{
      this.setState({index: -1});
      this.setState({movieList : []});
      await this.fetchMovieAgain();
    }
  }
   
  render () {
    const {movieList,index,movieLanguage} = this.state;
    var cardStyle = {
      margin: '10px',
      display: 'block',
      backgroundColor: 'rgb(241, 241, 241)',
      width: '100',
      fontSize: '18px',
      transitionDuration: '0.3s',   
  }
 
    return (
      <Grid container style={{maxwidth: "100vw", color:"blue", marginTop: "30px"}}>
          <Grid item  xs={12} sm={12} md={12} lg={12}>
            <span>Choose Movie language</span>
            <RadioGroup row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={movieLanguage}
              onChange={this.handleChange}
            >
              <FormControlLabel value="hi" control={<Radio />} label="Hindi(हिंदी)" />
              <FormControlLabel value="ta" control={<Radio />} label="Tamil(தமிழ்)" />
              <FormControlLabel value="kn" control={<Radio />} label="Kannada(ಕನ್ನಡ)" />
              <FormControlLabel value="ml" control={<Radio />} label="Malayalam(ಕನ್ನಡ)" />
            </RadioGroup>
          </Grid>

          <Grid item  xs={12} sm={12} md={12} lg={12}>
          {
            <div>
            { (movieList.length > 0 && index > -1 && index < movieList.length) ?
              <div>
                <Card style={cardStyle}>
                  <Grid item  xs={12} sm={12} md={12} lg={12}>
                    <Typography style={{float: "centre",margin: "10px"}} justifyContent="center" color="text.secondary">
                      {movieList[index].original_title} ({movieList[index].title})
                    </Typography> 
                    <Box
                      component="img"
                      sx={{
                        width: "100vw",
                        maxHeight: { xs: 300, md: 300 },
                        maxWidth: { xs: 600, md: 650 },
                      }}
                      alt="Play Dumb Charades online"
                      src={"http://image.tmdb.org/t/p/w500" + movieList[index].poster_path }
                    />
                    <Typography color="text.secondary">
                    {(movieList[index].overview)}
                    </Typography>
                  </Grid> 
                  <Toolbar style={{float: "centre"}}>
                    <Button variant="contained" onClick={async () => {await this.nextMovie()}}>
                      Click For Next Movie
                    </Button>
                  </Toolbar> 
                </Card>
              </div>    
              :
              <div>
                 <Typography justifyContent="center" color="text.secondary">Wait while we are fetching movie ...</Typography> 
              </div>
            }
          </div>
          }
          </Grid>
        </Grid>
  );
}
}

export default DumbCharades;

